/**
 * @module full-app
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2022 Enlightware GmbH, Switzerland

import './full-app.scss';

import { loadBrowserLanguage } from 'translator/translator';

async function init() {
	loadBrowserLanguage();
	try {
		// working around webpack bug with WASM
		// see https://github.com/webpack/webpack/issues/6615
		await import('./full-app');
	} catch (e) {
		console.error('Error importing `full-app.ts`:', e);
	}
}

void init();
