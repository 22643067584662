/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2022 Enlightware GmbH, Switzerland

import { ProductWithLicenceId } from 'network/api-types';
import { getLoggedInUserInfo } from './parameters';

const ProductFeatures = new Map<string, string[]>([
	['HomePremium', ['paidBasic']],
	['Classroom', ['paidBasic', 'assumeOnboarded']],
	['MultiClassroom', ['paidBasic', 'assumeOnboarded']],
]);

export function latestFeatureExpiration(products: ProductWithLicenceId[], feature: string) {
	let delta = Number.NEGATIVE_INFINITY;
	for (const { product, expiresInSecs } of products) {
		if (ProductFeatures.get(product)?.includes(feature) ?? false) {
			if (expiresInSecs === null) {
				delta = Number.POSITIVE_INFINITY;
			} else {
				delta = Math.max(delta, expiresInSecs);
			}
		}
	}
	return delta;
}

export function productsProvideFeature(products: ProductWithLicenceId[], feature: string) {
	return latestFeatureExpiration(products, feature) >= 0;
}
