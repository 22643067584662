/**
 * A collection of utilities related to types, serialization, rendering and HTML/DOM.
 * @module utils
 * @preferred
 */
/** comment to work-around limitation of typedoc module plugin */


// Copyright 2018-2022 Enlightware GmbH, Switzerland

import { robustStringify } from './text';

/**
 * Perform a logical XOR.
 * @param lhs -left hand side
 * @param rhs - right hand side
 * @returns lhs XOR rhs
 */
export function logicalXor(lhs: boolean, rhs: boolean): boolean {
	return ((lhs ? 1 : 0) ^ (rhs ? 1 : 0)) !== 0;
}

/**
 * Return [[value]], throw if null.
 * @param value - Value of type T | null
 * @param message - An optional user message to send with the exception
 * @return value of type T or throw if value is null
 */
export function nonnull<T>(value: T | null, message: string | null = null): T {
	const userMessage = message !== null ? message : 'value is null';
	if (value === null) {
		throw new TypeError(`nonnull: ${userMessage}`);
	}
	return value;
}

/**
 * Return [[value]], throw if undefined.
 * @param value - Value of type T | undefined
 * @param message - An optional user message to send with the exception
 * @return value of type T or throw if value is undefined
 */
export function defined<T>(value: T | undefined, message: string | null = null): T {
	const userMessage = message !== null ? message : 'value is undefined';
	if (value === undefined) {
		throw new TypeError(`defined: ${userMessage}`);
	}
	return value;
}

/** Return a string version of value */
export function toStr(value: any): string {
	if (Object(value) !== value) {
		// primitive value
		return value + '';
	}
	const className = value.constructor?.name;
	const textValue = robustStringify(value);
	if (className !== undefined) {
		return `${className}(${textValue})`;
	} else {
		return textValue;
	}
}

// downcast, for a discussion why it is cumbersome, see
// https://github.com/Microsoft/TypeScript/issues/2444
// https://stackoverflow.com/questions/36886082/abstract-constructor-type-in-typescript

export type Constructor<T> = Function & { prototype: T };

/**
 * Return a value casted to U. Throws if cast fails.
 * @param type - The type to cast to, this allows to pass abstract classes using [[Constructor]] type.
 * @param value - The value to cast from.
 * @return object of type U or throw if cannot cast to U.
 */
export function cast<U>(type: Constructor<U>, value: unknown): U {
	if (!(value instanceof type)) {
		throw new TypeError(`cast error: value ${toStr(value)} is not an instance of ${type.toString()}`);
	}
	return value as U;
}

/**
 * Return an object of type T downcasted to U. Throws if downcast fails.
 * @param type - The type to downcast to, this allows to pass abstract classes using [[Constructor]] type.
 * @param object - The object to downcast from.
 * @return object of type U or throw if cannot cast to U.
 */
export function downcast<T, U extends T>(type: Constructor<U>, object: T): U {
	return cast(type, object);
}

/**
 * Return an object of type T downcasted to U if it is of type U, null otherwise.
 * @param type - The type to downcast to, this allows to pass abstract classes using [[Constructor]] type.
 * @param object - The object to downcast from, possibly null.
 * @return object of type U or null cannot cast to U.
 */
export function dynamicCast<T, U extends T>(type: Constructor<U>, object: T | null): U | null {
	if (!(object instanceof type)) {
		return null;
	}
	return object as U;
}

/**
 * Return a value casted to string (the value, not the wrapper object). Throws if cast fails.
 * @param value - The value to cast from.
 * @return string or throw if value is not a string.
 */
export function castStringValue(value: unknown): string{
	if (!(typeof value === 'string')) {
		throw new TypeError(`cast error: value ${toStr(value)} is not a string`);
	}
	return value;
}

export function isRunningInJest() {
	return !!(global as any).IS_RUNNING_IN_JEST;
}

export function castArrayWithTypeChecker<U>(typeChecker: (value: unknown) => boolean, typename: string, values: unknown): U[] {
	if (isRunningInJest()) {
		return values as U[];
	}

	if (!(values instanceof Array)) {
		throw new TypeError(`cast error: value '${toStr(values)}' is not an array (of type ${typename}), it is a ${typeof values}`);
	}
	if (values.length > 0) {
		for (let i = 0; i < values.length; ++i) {
			const value = values[i] as unknown;
			if (!typeChecker(value)) {
				throw new TypeError(`cast error: value '${toStr(value)}' at index ${i} is not an instance of ${typename}`);
			}
		}
	}
	return values as U[];
}

/**
 * Return an array of values casted to U. Throws if cast fails. Slow as each element of the array must be checked for being of type U.
 * @param type - The type to cast to, this allows to pass abstract classes using [[Constructor]] type.
 * @param object - The values to downcast from.
 * @return objects of type U or throw if any cannot cast to U.
 */
export function castArray<U>(type: Constructor<U>, values: unknown): U[] {
	return castArrayWithTypeChecker((value: unknown) => value instanceof type, type.toString(), values);
}

/**
 * Return an array of values casted to strings. Throws if cast fails. Slow as each element of the array must be checked for being of type string.
 * @param object - The values to downcast from.
 * @return string array or throw if any cannot cast to string.
 */
export function castStringArray(values: unknown): string[] {
	return castArrayWithTypeChecker((value: unknown) => typeof value === 'string', 'string', values);
}

/**
 * Return an array of elements of type T downcasted to U. Throws if downcast fails. Slow as each element of the array must be checked for being of type U.
 * @param type - The type to downcast to, this allows to pass abstract classes using [[Constructor]] type.
 * @param object - The objects to downcast from.
 * @return objects of type U or throw if any cannot cast to U.
 */
export function downcastArray<T, U extends T>(type: Constructor<U>, objects: T[]): U[] {
	return castArray(type, objects);
}

/**
 * Return an array of elements of type T downcasted to U if all elements are of type U, null otherwise. Slow as each element of the array must be checked for being of type U.
 * @param type - The type to downcast to, this allows to pass abstract classes using [[Constructor]] type.
 * @param object - The objects to downcast from.
 * @return objects of type U or null if not all elements can be casted to U.
 */
export function dynamicCastArray<T, U extends T>(type: Constructor<U>, objects: (T | null)[]): U[] | null {
	if (objects.length > 0) {
		for (const object of objects) {
			if (!(object instanceof type)) {
				return null;
			}
		}
	}
	return objects as U[];
}

/**
 * Return [[value]], throw if not an integer
 * @param value the number to check for being an integer
 * @return value if it is an integer or throw if not
 */
export function integer(value: number) {
	if (!Number.isInteger(value)) {
		throw new TypeError(`value ${toStr(value)} is not an integer`);
	}
	return value | 0;
}

/**
 * Return [[value]], throw if not an unsigned integer
 * @param value the number to check for being an unsigned integer
 * @return value if it is an unsigned integer or throw if not
 */
export function unsigned(value: number) {
	const intValue = integer(value);
	if (intValue < 0) {
		throw new TypeError(`value ${toStr(value)} is not unsigned`);
	}
	return value;
}

/**
 * Return whether [[obj]] has a property for the key [[key]] without failing the TS type checking.
 * Taken from: https://dev.to/kingdaro/indexing-objects-in-typescript-1cgi
 * @param obj Some object
 * @param key Some potential key
 * @return whether [[obj]] has a property for the key [[key]]
 */
export function objectHasKey<O extends Object>(obj: O, key: keyof any): key is keyof O {
	return key in obj;
}

/**
 * Return [[obj]][[[key]]] or null if [[key]] is not a property of [[obj]].
 * @param obj Some object
 * @param key Some potential key
 * @return [[obj]][[[key]]] or null if [[key]] is not a property of [[obj]]
 */
export function getPropByNameOrNull<O extends Object>(obj: O, key: keyof any) {
	return objectHasKey(obj, key) ? obj[key] : null;
}

/** Cast to this to access readonly members of T */
export type Mutable<T> = { -readonly [P in keyof T]: T[P] };

/** Return whether value is an ArrayBuffer */
export function isArrayBuffer(value: unknown): value is ArrayBuffer {
	return (
		(typeof value === 'object' && value !== null && 'BYTES_PER_ELEMENT' in value) ||
		value instanceof ArrayBuffer
	);
}

export type TypeOfReturnValues = 'undefined'| 'number' | 'string' | 'boolean'| 'bigint' | 'symbol' | 'object' | 'function';

export type RemoveIndex<T> = {
	[ K in keyof T as string extends K ? never : number extends K ? never : K ] : T[K]
};

export type KnownKeys<T> = keyof RemoveIndex<T>;
